import React from 'react';
import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './App.css';

import Menu from './components/Menu'
import LandingPage from './pages/LandingPage'
import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

function App() {

  const [isLoggedIn
    // , setLoggedIn 
    ] = useState(false);

  const isDev = (process.env.NODE_ENV === 'development');

  // <Metadata />
  // <Layout>
  //   <Routes>

  return ( 
    <>
    
    <Router>
      {(isLoggedIn || isDev) && <Menu sticky="top" />}
      <Routes>
        {(!isLoggedIn && !isDev) ? 
          <>
          <Route exact path="/" element={<LandingPage />} />
          <Route path='*' element={<LandingPage />}  />
          </>
        : 
          <>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path='*' element={<NotFound />}  />
          </>
        }        
      </Routes>      
    </Router>
    </>
    );
  }

export default App;
