import React from 'react';
import './Logo.css'; 

const Logo = ({ fillColor, logoWidth, textPosition }) => {
  
  const logoChildCss = 
    (textPosition === 'right') ? 'logoChild ' 
    : (textPosition === 'topMenu') ? 'logoChild ' 
    : '';
  
  
  return (
    <div className='logoParent'>
      <div className='logoChild'>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={logoWidth}
          // width="200px"
          // height="142px"
          viewBox="0 0 574.000000 406.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g transform="translate(0.000000,406.000000) scale(0.100000,-0.100000)" fill={fillColor} stroke="none">
            <path d="M2000 4050 c-67 -19 -126 -65 -157 -123 -27 -51 -21 -137 34 -467 28 -168 54 -323 57 -345 4 -22 11 -67 16 -100 5 -33 12 -80 15 -105 3 -25 8 -52 10 -60 2 -8 7 -42 10 -75 4 -33 8 -62 10 -64 5 -5 226 82 285 112 l45 24 -4 239 c-2 131 -4 246 -6 254 -1 8 -6 56 -9 105 -4 50 -9 106 -11 125 -2 19 -6 71 -10 115 -15 210 -21 235 -69 292 -47 56 -150 91 -216 73z"/> 
            <path d="M3445 4013 c-52 -17 -87 -48 -112 -98 -17 -32 -21 -62 -23 -150 -1 -60 -3 -126 -5 -145 -1 -19 -6 -71 -10 -115 -4 -44 -13 -117 -21 -163 -14 -80 -13 -221 1 -452 23 -361 22 -416 -3 -472 -38 -84 -134 -148 -222 -148 -43 0 -115 25 -153 54 l-34 26 -18 -27 c-79 -122 -253 -155 -378 -72 l-45 29 -73 -45 c-81 -50 -103 -55 -119 -26 -17 32 -12 57 15 69 54 25 167 106 223 160 85 83 114 137 120 228 5 77 -4 114 -41 167 l-19 28 -31 -21 c-82 -55 -299 -150 -579 -252 -31 -11 -33 -22 -18 -95 14 -74 20 -128 26 -258 3 -66 7 -131 10 -143 13 -61 381 -174 644 -197 36 -3 75 -8 86 -10 25 -6 34 -32 22 -64 -8 -23 -11 -23 -93 -17 -47 4 -91 9 -98 12 -10 4 -13 -51 -12 -263 0 -229 -5 -356 -20 -473 -12 -94 -17 -136 -21 -154 -2 -11 -6 -36 -9 -56 -3 -20 -8 -47 -10 -60 -3 -14 -7 -36 -10 -50 -19 -97 -42 -191 -79 -312 -20 -65 -36 -122 -36 -127 0 -5 19 -20 43 -34 85 -51 159 -141 183 -224 15 -52 16 -53 55 -53 l39 0 0 690 c0 646 1 690 18 698 18 10 49 8 60 -3 4 -4 7 -317 7 -696 l0 -689 47 0 c32 0 48 4 49 13 0 6 0 381 0 833 1 451 4 826 8 833 5 7 22 11 42 9 l34 -3 3 -842 2 -843 50 0 50 0 0 690 c0 646 1 690 18 698 18 10 49 8 60 -3 4 -4 7 -317 7 -696 l0 -689 42 0 c34 0 41 3 39 18 -2 9 4 47 13 83 19 74 63 140 122 182 l37 27 -14 47 c-22 70 -58 221 -69 288 -3 17 -7 41 -10 55 -20 109 -42 290 -50 420 -4 52 -8 120 -10 150 -5 79 -6 478 0 523 5 42 2 43 -69 22 -69 -21 -85 -19 -96 11 -14 36 2 60 47 69 124 23 510 180 515 209 1 6 2 180 3 386 1 329 8 537 20 620 2 14 6 52 10 85 3 33 14 110 25 171 27 154 61 395 64 454 5 124 -130 224 -249 183z"/> 
            <path d="M1109 3556 c-2 -2 -30 -6 -61 -10 -534 -56 -948 -395 -1015 -831 -9 -62 -10 -233 -1 -300 29 -214 131 -399 303 -548 157 -136 309 -224 625 -360 296 -128 371 -166 440 -224 37 -31 70 -96 71 -144 3 -104 -54 -160 -201 -196 -54 -13 -390 -13 -450 0 -14 3 -54 11 -90 17 -36 7 -76 14 -90 17 -120 22 -354 104 -507 178 -62 30 -116 56 -120 58 -5 1 -8 -191 -8 -427 l0 -430 80 -37 c105 -49 273 -104 380 -125 11 -2 31 -6 45 -9 14 -3 40 -8 59 -11 18 -3 43 -8 55 -10 11 -2 41 -7 66 -10 25 -3 56 -7 70 -10 156 -26 626 -25 760 1 13 2 42 7 64 11 22 3 43 7 46 9 3 2 23 6 45 9 68 11 247 75 325 117 105 57 260 176 260 201 0 21 -35 4 -78 -37 -85 -83 -201 -72 -248 25 -55 112 40 233 163 208 29 -6 54 -22 86 -53 33 -34 52 -45 75 -45 29 0 30 3 46 68 9 37 27 122 40 190 l23 122 -25 0 c-16 0 -39 -14 -65 -41 -63 -64 -121 -77 -193 -43 -108 52 -100 222 13 265 64 24 124 6 182 -55 26 -27 43 -36 68 -36 30 0 33 3 34 33 0 17 2 41 4 52 2 11 7 76 11 145 3 69 7 130 8 135 0 6 -12 11 -28 13 -22 3 -38 -5 -70 -36 -53 -51 -76 -63 -131 -64 -34 0 -53 6 -81 27 -112 85 -57 265 80 265 50 0 80 -14 132 -62 26 -24 57 -43 71 -44 24 -1 25 1 20 37 -9 67 -123 215 -230 297 -29 23 -93 58 -142 77 -110 45 -140 62 -155 91 -13 24 -93 63 -305 150 -364 148 -498 223 -527 294 -51 126 31 224 221 265 66 15 332 15 411 0 37 -7 179 -40 218 -51 23 -7 24 -6 17 46 -4 29 -8 60 -10 69 -1 9 -6 39 -9 66 -4 28 -11 79 -17 115 -5 36 -12 76 -14 90 -12 76 -47 297 -50 310 -2 8 -6 41 -10 73 -9 67 -16 73 -100 82 -33 4 -69 9 -80 12 -24 6 -500 15 -506 9z"/> 
            <path d="M3739 3518 c0 -12 -2 -30 -4 -38 -24 -113 -48 -283 -60 -435 -15 -179 -20 -604 -10 -810 4 -98 -5 -150 -33 -182 -22 -26 -110 -71 -257 -134 l-110 -46 -2 -39 c-2 -58 -5 -276 -4 -296 2 -26 40 -11 87 34 72 69 157 79 220 25 55 -46 67 -114 34 -183 -25 -50 -74 -78 -138 -77 -47 1 -74 16 -126 68 -20 19 -45 35 -57 35 -22 0 -22 0 -15 -163 3 -89 8 -171 10 -182 3 -11 5 -23 5 -27 2 -23 54 -4 92 33 49 47 79 61 131 61 156 -2 199 -218 56 -282 -68 -30 -124 -14 -195 56 -19 19 -43 34 -55 34 -20 0 -20 -3 -14 -67 6 -58 14 -115 41 -270 6 -36 14 -43 50 -43 27 0 43 9 74 40 74 75 162 85 224 27 102 -95 38 -261 -99 -259 -54 1 -77 13 -127 65 -30 31 -42 38 -62 33 -14 -4 -25 -10 -25 -14 0 -4 13 -50 29 -101 29 -92 31 -121 6 -121 -31 0 -148 -110 -132 -126 5 -6 1082 -5 1117 0 14 3 52 7 85 11 33 3 76 10 95 15 19 5 51 12 70 15 19 3 81 21 137 40 460 154 770 513 893 1035 10 47 22 103 25 125 3 22 8 51 10 65 2 14 7 50 10 80 3 30 8 78 10 105 8 72 8 313 0 425 -7 112 -34 273 -65 390 -47 183 -155 409 -261 550 -212 282 -525 470 -873 526 -21 3 -48 8 -60 11 -11 3 -180 8 -373 10 l-353 5 -1 -24z m682 -818 c271 -51 471 -335 505 -720 6 -70 5 -223 -2 -292 -36 -369 -189 -612 -440 -698 -78 -27 -138 -34 -329 -34 l-160 -1 -1 878 -2 877 188 0 c103 0 212 -5 241 -10z"/> 
            <path d="M2518 3301 c-14 -5 -45 -19 -67 -32 -40 -22 -41 -24 -42 -78 0 -48 2 -210 4 -277 1 -21 4 -20 51 12 28 19 58 34 68 34 26 0 96 -74 120 -127 15 -34 22 -72 24 -133 4 -128 -32 -214 -134 -316 -38 -38 -41 -44 -27 -58 21 -20 89 -38 123 -32 90 15 158 83 163 162 2 29 -9 292 -16 374 -2 25 -7 126 -11 225 -7 198 -3 187 -82 226 -47 23 -132 33 -174 20z"/>
            <path d="M2934 3284 c-42 -15 -88 -50 -80 -62 2 -4 7 -88 11 -187 3 -99 8 -200 10 -225 2 -25 7 -108 10 -184 8 -153 13 -178 52 -218 55 -57 150 -61 214 -8 46 38 61 87 54 186 -3 44 -8 108 -10 144 -5 71 -16 347 -19 456 l-2 68 -50 23 c-57 26 -128 29 -190 7z"/>
            <path d="M2152 1543 c-21 -8 -40 -56 -31 -80 8 -22 38 -43 61 -43 7 0 31 14 52 31 l38 32 -35 31 c-35 31 -58 39 -85 29z"/> 
            <path d="M3445 1537 c-11 -6 -29 -20 -40 -31 l-22 -21 36 -32 c39 -37 43 -38 74 -22 42 23 50 68 17 96 -24 21 -41 24 -65 10z"/>
            <path d="M2122 1067 c-46 -21 -36 -99 15 -112 18 -4 36 1 59 17 45 31 47 41 15 72 -32 31 -57 37 -89 23z"/>
            <path d="M3445 1047 l-36 -32 28 -26 c43 -41 68 -45 98 -15 30 30 32 57 6 80 -32 29 -58 27 -96 -7z"/>
            <path d="M2020 585 c-49 -52 19 -128 81 -89 8 4 23 17 35 29 l21 20 -30 28 c-16 15 -32 28 -36 28 -34 7 -53 3 -71 -16z"/>
            <path d="M3561 601 c-7 -4 -24 -18 -39 -31 l-28 -24 30 -28 c16 -15 32 -28 35 -29 3 0 14 -2 23 -4 22 -4 54 23 62 51 12 39 -49 86 -83 65z"/>
          </g>
        </svg>
      </div>

    <div className={logoChildCss + 'logoText'}>
      {(textPosition === 'below') && 
        <>
        <h1 className="brand">STRUM<br />DADDY</h1>
        </>
      }

      {(textPosition === 'right') && 
        <>
        <div className='brand wordmark'>
        STRUM<br />DADDY
        </div>
        </>
      }    

      {(textPosition === 'topMenu') && 
        <>
        <div className='brand wordmark topmenu'>
        STRUM<br />DADDY
        </div>
        </>
      }          
    </div>  
    
  </div>
  )
}

Logo.defaultProps = {
  usageLocation: 'landing',
  fillColor: 'white',
  logoWidth: '200px',
  textPosition: 'none',
};

export default Logo;