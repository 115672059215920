import React from 'react';
import './MenuTile.css'; 

const MenuTile = () => {
  return (
    <div
      style={{
        width: '110px',
        height: '110px',
        border: '1px solid white',
        borderRadius: '10px',        
      }}
    ></div>
  );
};

export default MenuTile;