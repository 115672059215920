import React from 'react';
import Logo from '../components/Logo';
import './LandingPage.css'; // Import your CSS file here

const LandingPage = () => {
  
  // <img src="/path/to/logo.png" alt="Logo" className="logo" />

  return (
    <>
    <div className="landing-page">
      <div className="logo-container">
        
        <div className="coming-soon">
          <Logo textPosition='right' />
          
          <h3>Coming Soon</h3>
          <p className='were_working'>We're working on something amazing.
          <br />Stay tuned!</p>
        </div>

      </div>
    </div>
  </>
  );
};

export default LandingPage;