// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { 
    faCheck, 
    faSearch, 
    faTrashAlt,
    } from '@fortawesome/free-solid-svg-icons';

library.add(
  faCheck,
  faSearch,
  faTrashAlt,  
  // more icons go here
);