import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import Logo from '../components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './Menu.css'; 
import MenuTile from './MenuTile';

function Menu() {

  const handleMouseOver = () => {
  };

  const handleMouseOut = () => {
  };


  return (
    <div className="Menu">
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark" >
      
      {/* 
      ---------------------------------------------------------------------------------
      ---------------------------------------------------------------------------------
      Content for narrow screen sizes ** mobile
      ---------------------------------------------------------------------------------
      ---------------------------------------------------------------------------------
      */}
      <Container className="d-sm-none">

        <Row style={{width:'100%'}}>
          <Col xs={10} sm={10} md={10}>
            <Link className='nav-link' to="/">
              <Logo logoWidth={70} textPosition='topMenu' />
            </Link>
          </Col>
          <Col xs={2} sm={2} md={2}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Col>
        </Row>

        <Row style={{width:'100%'}}>
        <Form className="d-flex" style={{marginTop: '10px'}}>
            <Form.Control
              type="search"
              placeholder="Search"
              className=""
              aria-label="Search"
              style={{backgroundColor: '#000', padding: '0.9rem', color: '#ffffff'}}
            />
            <Button variant="outline-success" style={{marginLeft:'10px', paddingLeft: '20px', paddingRight: '20px'}}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
        </Form>        
        </Row>

        <Row>
        <Navbar.Collapse id="responsive-navbar-nav">          
          <Nav className="me-auto">
            <div>
              <div className='MenuTile MenuTileMobile'>
              <Link className='nav-link' to="/about"><MenuTile /></Link>
              </div>
              <div className='MenuTile MenuTileMobile'>
              <Link className='nav-link' to="/about"><MenuTile /></Link>
              </div>
              <div className='MenuTile MenuTileMobile'>
              <Link className='nav-link' to="/about"><MenuTile /></Link>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
        </Row>

      </Container>


      {/* 
      ---------------------------------------------------------------------------------
      ---------------------------------------------------------------------------------
      Content for wide screen sizes
      ---------------------------------------------------------------------------------
      ---------------------------------------------------------------------------------
       */}
      <Container className="d-none d-sm-block">
            
      <Row onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        <Col md={3}>
        <Link to="/">
        <Logo logoWidth={70} textPosition='topMenu' />
        </Link>
        </Col>

        <Col md={9}>
          <Form className="d-flex" style={{marginTop: '10px'}}>
              <Form.Control
                type="search"
                placeholder="Search"
                className=""
                aria-label="Search"
                style={{backgroundColor: '#000', color: '#ffffff'}}
              />
              <Button variant="outline-success" style={{marginLeft:'10px'}}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
          </Form>       

        </Col>
      </Row>
      <Row className="MenuWrapper" style={{marginTop: '10px'}}>

        <Col md={3}></Col>
        <Col md={9}>

          <div className="MenuTileWrapper">
            <div className='MenuTile'>
            <Link className='nav-link' to="/about"><MenuTile /></Link>
            </div>
            <div className='MenuTile'>
            <Link className='nav-link' to="/about"><MenuTile /></Link>
            </div>
            <div className='MenuTile'>
            <Link className='nav-link' to="/about"><MenuTile /></Link>
            </div>
          </div>
        
        </Col>
       
      </Row>

      </Container>

    </Navbar>
    </div>
  );
}

export default Menu; 