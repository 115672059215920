import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import VideosList from '../components/VideosList';

const Home = () => {
  
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const videoUrl = 'https://www.youtube.com/watch?v=__AS_V4Xxnw'

  const onPlay = () => {
    console.log("onPlay");
  };

  const onProgress = (progressData) => {
    console.log(progressData);
  };

  const thumb = 'https://i.ytimg.com/vi/__AS_V4Xxnw/default.jpg';

  return (
    <>   
    <h1>Home Page 
    <br /><br />
    </h1>

    <VideosList></VideosList>  


    


















    <div>
      <div onClick={handleShow} style={{ cursor: 'pointer' }}>
        {/* You can customize the thumbnail appearance here */}
        <img
          src={thumb}
          alt="Video Thumbnail"
          // style={{ width: '100%', height: '89%' }}
        />
      </div>

      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Body>
          <ReactPlayer 
            url={videoUrl} 
            controls 
            width="100%" 
            height="400px"
            playing
            onPlay={onPlay}
            onProgress={onProgress}
            progressInterval={1000}
            config={{
              youtube: {
                playerVars: { showinfo: 1 }
              }
            }}
            />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    </>
  )
};

export default Home