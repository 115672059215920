import React, { useState, useEffect, useRef } from 'react';
import Masonry from 'masonry-layout';
import InfiniteScroll from "react-infinite-scroll-component";
import Axios from 'axios'

const VideosList = () => {

    const [itemOffset, setItemOffset] = useState(0);
    const [items, setItems] = useState([]);
    const [initialLoad, setInitialLoad] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const qty = 30;

    const style = {
        height: 30,
        border: "1px solid green",
        margin: 6,
        padding: 8
      };


    const loadVideos = () => {
        console.log('loadVideos');

        let args = {};
            args.params = {};
            args.params.qty = qty;
            args.params.offset = itemOffset;

        setItemOffset(itemOffset + qty);
          
        Axios.get('http://127.0.0.1:3080/api/video', args)
          .then((response) => {
            setItems(
              items.concat(response.data)
              )
    			});
        
        }

	  useEffect(() => {    
      if (!initialLoad) { 
            setInitialLoad(true);
            loadVideos();            
            }
        }, [initialLoad]);

    return (        
        <InfiniteScroll
          dataLength={items.length}
          next={loadVideos}
          hasMore={hasMore}
          // height={400}
          loader={<h4>Loading...</h4>}
        >
          {items.map((item, index) => (
            <div>{index}</div>
            // <ReviewVideo key={index} video={item} />
          ))}
        </InfiniteScroll>      
        );
  		
	} // end VideosList

export default VideosList